import React, { FC } from 'react';
import { Row } from '../../atoms/grid';
import clsx from 'clsx';
import { CloseIcon } from '../../molecules/icons';
import './chips.scss';
import { Text } from '../../atoms/text/text';

interface ChipProps {
  label: string;
  className?: string;
  onClose?: Function;
}

export const Chips: FC<ChipProps> = ({ label, onClose, className }) => {
  return (
    <Row className={clsx('chips-container', className)}>
      <div onClick={() => onClose()}>
        <CloseIcon iconWidth={32} />
      </div>
      <Text variant="u3b" className="chips-label" color="dark-green">
        {label}
      </Text>
    </Row>
  );
};
