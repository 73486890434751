import { useSelectOptions } from './use-select-options';

interface PropertyType<T> {
  label: string;
  items: T[];
}

function sortOptions<T>(
  items: T[],
  getPropertyValue: Function = (): void => undefined
): PropertyType<T>[] {
  return items.reduce<PropertyType<T>[]>(
    (acc: PropertyType<T>[], item: T): PropertyType<T>[] => {
      const value = getPropertyValue(item);
      let currentProperty = acc.find(p => p.label === value);
      if (!currentProperty) {
        currentProperty = {
          label: value,
          items: []
        };
        acc.push(currentProperty);
      }
      currentProperty.items.push(item);
      return acc;
    },
    []
  );
}

export const useMultipleSelectOptions = <
  T extends { label?: string; value?: any }
>(
  sortByProperty: Function,
  options: PropertyType<T>[],
  initialOptions?: T[],
  singleSelectMode = false
): [PropertyType<T>[], T[], Function, Function] => {
  const processedOptions = options.reduce(
    (acc: T[], option) => [
      ...acc,
      ...option.items.map((i: any) => ({
        ...i,
        category: option.label
      }))
    ],
    []
  );

  const [
    _options,
    selectedOptions,
    onSelectOption,
    clearSelection
  ] = useSelectOptions(processedOptions, initialOptions, singleSelectMode);

  return [
    sortOptions(_options, sortByProperty),
    selectedOptions,
    onSelectOption,
    clearSelection
  ];
};
