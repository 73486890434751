import React, { FC, useEffect, useState } from 'react';
import clx from 'clsx';
import { Text } from '../../atoms/text/text';
import { Row } from '../../atoms/grid';
import { Dropdown } from '../../atoms/dropdown/dropdown';
import { useOnToggleScrolling } from '../../../hooks/use-on-toggle-scrolling';
import { Option } from '../../atoms/option/option';
import { Button } from '../../atoms/button/button';
import './exposed-filter.scss';
import { FilterIcon } from '../../atoms/icons/filter-icon/filter-icon';
import { useMask } from '../../../hooks/use-mask';
import { Chips } from '../../atoms/chips';

export type TSortBy = {
  label: string;
  value: string;
};

export type TFilterByItem = {
  label: string;
  value: string;
};

export type TFilterBy = {
  label: string;
  items: TFilterByItem[];
};

interface ExposedFilterProps {
  title?: string;
  sortBy?: TSortBy[];
  selectedSortBy?: TSortBy[];
  onSelectSortBy?: Function;
  filters?: TFilterBy[];
  selectedFilter?: TFilterByItem[];
  onSelectFilter?: Function;
  onClearFilter?: Function;
}

export const ExposedFilterPanel: FC<ExposedFilterProps> = ({
  title,
  sortBy,
  filters,
  selectedFilter,
  onSelectFilter,
  onClearFilter,
  selectedSortBy,
  onSelectSortBy
}) => {
  const [isOpen, onToggleOpen] = useOnToggleScrolling(false);
  const { setMask } = useMask();
  const [isShowAllSelected, setIsShowAllSelected] = useState(false);

  useEffect(() => {
    setMask(isOpen);
  }, [isOpen, setMask]);

  const toggleShowAll = () => {
    if (!isShowAllSelected && onClearFilter) {
      onClearFilter();
    }
    setIsShowAllSelected(selected => !selected);
  };

  const getExposedModeCTA = () => {
    if (filters?.length) {
      //if we have flters, display button CTA even on desktop
      return (
        <Button
          color="darkGreen"
          variant="outlined"
          size="small"
          className={clx('mr-tiny')}
          trackingLabel="Filter button on blog page - exposed filter panel module"
        >
          <FilterIcon iconWidth={36} />
          {title}
        </Button>
      );
    }

    return (
      <>
        <Text variant="p2a" className="mr-sm hidden-mobile">
          {title}
        </Text>
        <Button
          color="darkGreen"
          variant="outlined"
          size="small"
          className={clx('mr-tiny', 'hidden-desktop')}
          trackingLabel="Filter button on blog page - exposed filter panel module"
        >
          <FilterIcon iconWidth={36} />
          {title}
        </Button>
      </>
    );
  };

  return (
    <Row className="exposed-filter-container">
      <Dropdown
        heading={getExposedModeCTA()}
        isOpen={isOpen}
        className={clx(!filters?.length && 'hidden-desktop')}
        onToggleOpen={onToggleOpen}
        optionsContainerClass="bg-ivory exposed-filter-wrapper-content"
        closeIconContainerClass="d-flex text-white"
      >
        <div className="exposed-filter-popup-container">
          {sortBy?.length > 0 && (
            <div>
              <Text variant="p1a" className="mb-sm pb-xtiny filter-line">
                Sort By
              </Text>
              {sortBy.map((o, i: number) => {
                return (
                  <Option
                    option={o}
                    key={`value+${i}`}
                    onSelectOption={() => onSelectSortBy(o)}
                  ></Option>
                );
              })}
            </div>
          )}
          {filters?.length > 0 && (
            <div className="mt-sm mb-sm">
              <Text variant="p1a" className="mb-sm pb-xtiny filter-line">
                Filter By
              </Text>
              <div className="mb-md">
                <Option
                  option={{
                    value: 'show-all',
                    label: 'Show All',
                    isActive: isShowAllSelected
                  }}
                  key="show-all-options"
                  onSelectOption={() => toggleShowAll()}
                ></Option>
              </div>

              {filters.map((c, i) => {
                return (
                  <div key={i}>
                    <Text variant="u2">{c.label}</Text>
                    <div key={`options-${i}`} className="mt-xxs mb-md">
                      {c.items.map((o, j) => {
                        return (
                          <Option
                            option={o}
                            key={`value+${j}`}
                            onSelectOption={() => {
                              onSelectFilter(o);
                              setIsShowAllSelected(false);
                            }}
                          ></Option>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <Button
            color="sage"
            variant="filled"
            size="large"
            onClick={() => onToggleOpen()}
            className="w-full mt-auto"
            trackingLabel="Apply button on blog page - exposed filter panel module"
          >
            Apply
          </Button>
        </div>
      </Dropdown>

      {!filters?.length && (
        <Text variant="p2a" className="mr-sm hidden-mobile">
          {title}
        </Text>
      )}

      <div className="exposed-filter-pills">
        {sortBy.map((sortItem, index) => {
          return (
            <div
              className={clx(
                'mr-tiny',
                'exposed-filter',
                sortItem.value === selectedSortBy[0]?.value &&
                  'exposed-selected-filter'
              )}
              onClick={() => onSelectSortBy(sortItem)}
              key={`sort-by-filter${index}`}
            >
              <div>
                {selectedSortBy[0]?.value === sortItem.value && (
                  <svg
                    className="mr-tiny"
                    width="14"
                    height="13"
                    viewBox="0 0 14 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 7.32301L6.19414 11.456L13 1.54297"
                      stroke="#6FB39F"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                    />
                  </svg>
                )}
                <Text variant="u1a" color="dark-green">
                  {sortItem.label}
                </Text>
              </div>
            </div>
          );
        })}
      </div>
      <div className="selected-filter">
        {selectedFilter?.map(item => (
          <div className="mr-sm">
            <Chips
              onClose={() => onSelectFilter(item)}
              key={`filter-chip-${item.value}`}
              label={item.label}
            />
          </div>
        ))}
        {selectedFilter?.length > 1 && (
          <div onClick={() => onClearFilter()}>
            <Text className="clear-all-filter" color="sage" variant="u1b">
              Clear All
            </Text>
          </div>
        )}
      </div>
    </Row>
  );
};
