import React, { useRef } from 'react';
import { Text } from '../../atoms/text/text';
import { Button } from '../../atoms/button/';
import { InstagramIcon } from '../../atoms/icons/instagram-icon/instagram-icon';
import { Image } from '../../atoms/image/image';
import { ImageType } from '../../atoms/image/types';

import styles from './social-highlight.module.css';
import { ParsleyCarousel } from '../parsley-carousel/parsley-carousel';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { baseSanityNode } from '../../content/base-sanity-node';

const carouselConfig = {
  Desktop: {
    breakpoint: {
      max: 3000,
      min: 1024
    },
    items: 4,
    partialVisibilityGutter: 0
  },
  mobile: {
    breakpoint: {
      max: 768,
      min: 0
    },
    items: 1,
    partialVisibilityGutter: 105
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 768
    },
    items: 2,
    partialVisibilityGutter: 20
  }
};

export type SocialHighlightProps = {
  headline?: string;
  ctaLabel?: string;
  ctaUrl?: string;
};

const SocialHighlight = ({
  headline = 'Social Highlight',
  ctaLabel = 'Follow @parsleyhealth',
  ctaUrl = 'https://twitter.com/parsleyhealth'
}: SocialHighlightProps) => {
  const carousel = useRef<Carousel | null>(null);

  return (
    <div className="py-big">
      <Text variant="h2a" color="dark-green" className="mb-xl text-center">
        {headline}
      </Text>

      <div className={styles.carouselContainer}>
        <ParsleyCarousel
          arrows={false}
          ref={carousel}
          partialVisible={true}
          responsive={carouselConfig}
        >
          {Array(4)
            .fill(0)
            .map((_, index) => (
              <div
                key={`carousel_item-${index}`}
                className={styles.carouselImage}
              >
                <Image
                  asset={{
                    ...baseSanityNode,
                    url:
                      'https://images.unsplash.com/photo-1634813952292-5eb3f2d0d9ba?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2071&q=80'
                  }}
                  alt=""
                  type={ImageType.RECTANGLE}
                />
              </div>
            ))}
        </ParsleyCarousel>
      </div>

      <div className="flex flex-center">
        <Button
          color="darkGreen"
          variant="outlined"
          size="large"
          className="mb-md mt-xl mw-85 mx-auto flex-center"
          destination={ctaUrl}
          trackingLabel="Instagram button on blog page"
        >
          <InstagramIcon iconWidth="36" />
          {ctaLabel}
        </Button>
      </div>
    </div>
  );
};

export default SocialHighlight;
