import React from 'react';
import { Layout, Module } from '..';
import SocialHighlight from '../organisms/social-highlight/social-highlight';
import { ExposedFilterPanel } from '../molecules/exposed-filter/exposed-filter-panel';

import { useSelectOptions } from '../../hooks/use-select-options';
import { TSortBy } from '../molecules/exposed-filter/exposed-filter-panel';
import { useMultipleSelectOptions } from '../../hooks/use-multiple-select-options';

export default function Blog() {
  const sortOptionItems = [
    { label: 'Newest', value: 'Newest' },
    { label: 'Most popular', value: 'Most popular' },
    { label: 'Medically Viewed', value: 'Medically Viewed' }
  ];

  const filters = [
    {
      label: 'Hormonal Health',
      items: [
        { label: 'Autoimmune & Inflammation', value: 'Autoimmune' },
        { label: 'Digestive Issues', value: 'Digestive' }
      ]
    },

    {
      label: 'Menopause',
      items: [
        { label: 'Menopause Test One', value: 'menopause1' },
        { label: 'Another test one', value: 'anotherteest' }
      ]
    }
  ];

  const [
    filterOptions,
    selectedFilterOptions,
    onSelectFilterOptions,
    clearFilterSelection
  ] = useMultipleSelectOptions((i: any) => i.category, filters, [], false);

  const [
    sortOptions,
    selectedSortOptions,
    onSelectSortOptions
  ] = useSelectOptions<TSortBy>(sortOptionItems, [sortOptionItems[0]], true);

  return (
    <Layout>
      <Module></Module>
      <Module>
        <ExposedFilterPanel
          title="Sort By"
          filters={filterOptions}
          selectedFilter={selectedFilterOptions}
          onSelectFilter={onSelectFilterOptions}
          sortBy={sortOptions}
          selectedSortBy={selectedSortOptions}
          onSelectSortBy={onSelectSortOptions}
          onClearFilter={clearFilterSelection}
        />
      </Module>
      <Module>
        <SocialHighlight />
      </Module>
    </Layout>
  );
}
